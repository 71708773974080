const themeEl = () => document.getElementById("theme") as
  HTMLLinkElement & { setTheme: (value: string) => void; };

export const setTheme = (value: boolean) => themeEl().setTheme(value ? "dark" : "light");

export const getTheme = () => themeEl().getAttribute("theme-name") === "dark";

export const themeSubscribe = (func: (newValue: boolean) => void) => {
  const listener = (e: any) => func(e.detail === "dark");
  const el = themeEl();
  el.addEventListener("update", listener);
  return () => el.removeEventListener("update", listener);
};
