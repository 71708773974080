<template>
  <article class="item">
    <a :href="link" target="_blank">
      <img :src="image" :alt="name" />
      <span v-if="title" v-text="title" class="subtitle" :style="{color: color || 'var(--text-color)'}" />
    </a>
  </article>
</template>

<script>
export default {
  name: 'Item',
  props: {
    image: String,
    link: String,
    name: String,
    title: String,
    color: String
  }
};
</script>

<style lang="scss">
.item {
  background-color: rgba(255, 255, 255, 0.87);
  border-radius: 2rem;

  a {
    display: block;
    padding: 4rem 2rem;
    cursor: pointer;
  }

  img {
    width: 100%;
    display: block;
  }
  .subtitle {
    position: absolute;
    right: 42px;
    bottom: 28px;
    font-size: 28px;
    font-weight: 700;
  }
}
</style>
