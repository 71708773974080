<template>
  <div>
    <img class="bg" :src="bgImage" alt="" />
    <div class="container">
      <aside class="theme-switch">
        <Button
          :icon="
            !isDarkTheme ? 'mdi mdi-20px mdi-brightness-7' : 'mdi mdi-20px mdi-rotate-315 mdi-moon-waning-crescent'
          "
          class="p-button-blurred"
          @click="isDarkTheme = !isDarkTheme"
        />
      </aside>
      <section class="grid">
        <Item v-for="item in itemList" :key="item.key" v-bind="item" />
      </section>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
// import InputSwitch from 'primevue/inputswitch';
import Item from './Item.vue';
import {getTheme, setTheme, themeSubscribe} from '@/utils/theme';
import darkBg from '../assets/bg-dark.jpg';
import lightBg from '../assets/bg-light.jpg';
import platformSrc from '../assets/nv-platform.png';
import finderSrc from '../assets/nv-finder.png';
import monitoringSrc from '../assets/nv-monitoring.png';
import trafficSrc from '../assets/nv-traffic.png';
import ntechlabSrc from '../assets/ntech-lab.png';

const ITEMS = {
  platform: {
    image: platformSrc,
    name: 'Netvision'
  },
  fsb: {
    image: platformSrc,
    name: 'NetvisionFSB',
    title: 'СП Самара',
    color: '#F77102'
  },
  srviSamara: {
    image: platformSrc,
    name: 'NetvisionSRVI',
    title: 'СРВИ Самара',
    color: '#F77102'
  },
  spSamara: {
    image: platformSrc,
    name: 'NetvisionSP',
    title: 'СП Самара',
    color: '#F77102'
  },
  finder: {
    image: finderSrc,
    name: 'Netvision Finder'
  },
  monitoring: {
    image: monitoringSrc,
    name: 'Netvision Monitoring'
  },
  traffic: {
    image: trafficSrc,
    name: 'Netvision Traffic'
  },
  ntechlab: {
    image: ntechlabSrc,
    name: 'Find Face'
  }
};

export default {
  name: 'Page',
  components: {
    Item,
    Button
  },
  props: {
    links: Object,
    lightBg: String,
    darkBg: String
  },
  data() {
    return {
      items: ITEMS,
      isDarkTheme: true,
      themeSubscription: () => {}
    };
  },
  methods: {},
  mounted() {
    this.isDarkTheme = getTheme();
    this.themeSubscription = themeSubscribe((value) => (this.isDarkTheme = value));
  },
  beforeDestroy() {
    this.themeSubscription();
  },
  watch: {
    isDarkTheme(newValue) {
      setTheme(newValue);
    }
  },
  computed: {
    themeIconClass() {
      return ['mdi mdi-24px', this.isDarkTheme ? 'mdi-weather-night' : 'mdi-weather-sunny'];
    },
    bgImage() {
      return this.isDarkTheme ? this.darkBg || darkBg : this.lightBg || lightBg;
    },
    itemList() {
      return Object.entries(this.items)
        .filter(([key]) => key in this.links)
        .map(([key, value]) => {
          return {
            ...value,
            link: this.links[key],
            key
          };
        });
    }
  }
};
</script>

<style lang="scss">
.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.bg {
  display: block;
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(3px) hue-rotate(35deg) brightness(0.87);
}

.theme-switch {
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  background-color: var(--bgColor);
  opacity: 0.7;
  transition: opacity 150ms ease;
}

.theme-switch:hover {
  opacity: 1;
}

.theme-switch > * {
  margin: 0.25rem;
}

.grid {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.grid > * {
  flex: 0 1 30rem;
  margin: 1rem;

  opacity: 0.87;
  transform: scale(1);
  transition: opacity 200ms ease, transform 200ms ease;
}

.grid > *:hover {
  opacity: 1;
  transform: scale(1.05);
}
</style>
