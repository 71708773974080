import './set-public-path';
import Vue from 'vue';
import Page from '@/components/Page.vue';
import singleSpaVue from 'single-spa-vue';

Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h: any) {
      return h(Page, {
        props: {
          lightBg: this.props.lightBg,
          darkBg: this.props.darkBg,
          links: this.props.links
        }
      });
    }
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
export const update = vueLifecycles.update;
